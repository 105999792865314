const messages = {
  en: {
    "app.language": "English",
    "app.subTitle":
      "Feel free to ask any questions you might have about Pega Infinity™",
    "app.sampleQuestions": "Browse through sample questions",
    "app.ownQuestion": "or enter your own question here",
    "app.logoutToolTip": "Log out",
    "app.askButtonLabel": "Ask",
    "app.initialReply":
      "Thank you for your question. I'm going to need a brief moment to gather the information. Please bear with me.",
    "app.newAnswer":
      "I'm sorry, I'm tied up with a few other questions at the moment. I'm happy to help when I'm done.",
    "app.referencesTitle": "References",
    "app.disclaimerTitle": "Disclaimer",
    "app.disclaimerDesktop":
      "AI-generated answers may be incorrect. It's important not to disclose personal details and not to base crucial decisions on these answers.",
    "app.disclaimerMobile": "AI-generated answers may be incorrect.",
    "app.disclaimerModalTitle": "Auto-translated References",
    "app.disclaimerModalText":
      "You are about to be redirected to an automatically translated version of the Pega site. Please note that these translations have not been checked for accuracy or completeness and might contain errors including inappropriate language. Any differences or mistakes from the translation are not legally binding and should not be used for compliance or enforcement. In the event of concerns regarding accuracy, please refer to the official English version of the website.",
    "app.disclaimerModalCheckbox": "Do not show again",
    "app.disclaimerModalButton": "Close",
    "app.copyButton": "Copy answer",
    "app.copyText": "Copied to clipboard!",
    "app.thumbsUpButton": "Good answer",
    "app.thumbsUpText": "Thanks for the feedback!",
    "app.thumbsDownButton": "Bad answer",
    "app.thumbsDownText": "Thanks for the feedback!",
    questions: [
      {
        value: "q",
        label:
          "What are the core components of the Pega Platform, and how do they interact with each other?",
      },
      {
        value: "q",
        label:
          "How does Pega's case management functionality support complex business processes, and what are the key features that enable this support?",
      },
      {
        value: "q",
        label:
          "Can you explain the role of artificial intelligence in Pega's decisioning capabilities, and how does it help in personalizing customer interactions?",
      },
      {
        value: "q",
        label:
          "What reporting and analytics tools are available in the Pega Platform, and how can they be utilized to gain insights into business performance?",
      },
      {
        value: "q",
        label:
          "In what ways can I customize the user interface of a Pega application to match my company's branding and usability standards?",
      },
      {
        value: "q",
        label:
          "What features does Pega Customer Service offer to enable personalized interactions with customers based on their history and preferences?",
      },
      {
        value: "q",
        label:
          "Can you describe the case management capabilities within Pega Customer Service and how they help in resolving customer inquiries more efficiently?",
      },
      {
        value: "q",
        label:
          "How do I configure Pega Customer Service to automatically route customer cases to the most appropriate service agent based on skills and availability?",
      },
      {
        value: "q",
        label:
          "How do I implement a chatbot with Pega Customer Service to handle common customer inquiries and when should it escalate to a live agent?",
      },
      {
        value: "q",
        label:
          "How does Pega Sales Automation facilitate lead management, and what tools does it provide to prioritize and score leads effectively?",
      },
      {
        value: "q",
        label:
          "What are the key features of Pega Sales Automation that support the sales pipeline management process, and how do they contribute to closing deals faster?",
      },
    ],
  },
  sv: {
    "app.language": "Svenska",
    "app.subTitle": "Ställ gärna frågor du har om Pega Infinity™",
    "app.sampleQuestions": "Bläddra genom exempelfrågor",
    "app.ownQuestion": "eller skriv din egen fråga här",
    "app.logoutToolTip": "Logga ut",
    "app.askButtonLabel": "Fråga",
    "app.initialReply": "Tack för din fråga. Jag behöver en kort stund för att samla informationen. Vänligen ha tålamod.",
    "app.newAnswer": "Jag är tyvärr upptagen med några andra frågor just nu. Jag hjälper gärna till när jag är klar.",
    "app.referencesTitle": "Referenser",
    "app.disclaimerTitle": "Ansvarsfriskrivning",
    "app.disclaimerDesktop": "AI-genererade svar kan vara felaktiga. Det är viktigt att inte lämna ut personliga uppgifter och att inte basera viktiga beslut på dessa svar.",
    "app.disclaimerMobile": "AI-genererade svar kan vara felaktiga.",
    "app.disclaimerModalTitle": "Automatiskt översatta referenser",
    "app.disclaimerModalText": "Du kommer att omdirigeras till en automatiskt översatt version av Pega-webbplatsen. Observera att dessa översättningar inte har kontrollerats för noggrannhet eller fullständighet och kan innehålla fel inklusive olämpligt språk. Eventuella skillnader eller fel i översättningen är inte juridiskt bindande och bör inte användas för efterlevnad eller verkställighet. Vid frågor om noggrannhet, vänligen se den officiella engelska versionen av webbplatsen.",
    "app.disclaimerModalCheckbox": "Visa inte igen",
    "app.disclaimerModalButton": "Stäng",
    "app.copyButton": "Kopiera svar",
    "app.copyText": "Kopierat till urklipp!",
    "app.thumbsUpButton": "Bra svar",
    "app.thumbsUpText": "Tack för din feedback!",
    "app.thumbsDownButton": "Dåligt svar",
    "app.thumbsDownText": "Tack för din feedback!",
    questions: [
      {
        value: "q",
        label: "Vilka är kärnkomponenterna i Pega-plattformen och hur interagerar de med varandra?",
      },
      {
        value: "q",
        label: "Hur stöder Pegas ärendehanteringsfunktionalitet komplexa affärsprocesser, och vilka är de viktigaste funktionerna som möjliggör detta stöd?",
      },
      {
        value: "q",
        label: "Kan du förklara vilken roll artificiell intelligens spelar i Pegas beslutsfattande förmågor och hur det hjälper till att personalisera kundinteraktioner?",
      },
      {
        value: "q",
        label: "Vilka rapporterings- och analysverktyg finns tillgängliga i Pega-plattformen, och hur kan de användas för att få insikter om verksamhetens prestanda?",
      },
      {
        value: "q",
        label: "På vilka sätt kan jag anpassa användargränssnittet i en Pega-applikation för att matcha mitt företags varumärkes- och användbarhetsstandarder?",
      },
      {
        value: "q",
        label: "Vilka funktioner erbjuder Pega Customer Service för att möjliggöra personliga interaktioner med kunder baserat på deras historik och preferenser?",
      },
      {
        value: "q",
        label: "Kan du beskriva ärendehanteringsmöjligheterna inom Pega Customer Service och hur de hjälper till att lösa kundförfrågningar mer effektivt?",
      },
      {
        value: "q",
        label: "Hur konfigurerar jag Pega Customer Service för att automatiskt dirigera kundärenden till den mest lämpliga serviceagenten baserat på kompetens och tillgänglighet?",
      },
      {
        value: "q",
        label: "Hur implementerar jag en chatbot med Pega Customer Service för att hantera vanliga kundförfrågningar och när bör den eskalera till en live-agent?",
      },
      {
        value: "q",
        label: "Hur underlättar Pega Sales Automation leadhantering, och vilka verktyg tillhandahåller det för att effektivt prioritera och poängsätta leads?",
      },
      {
        value: "q",
        label: "Vilka är de viktigaste funktionerna i Pega Sales Automation som stöder försäljningspipelinehanteringsprocessen, och hur bidrar de till att stänga affärer snabbare?",
      },
    ],
  },
  es: {
    "app.language": "Español",
    "app.subTitle":
      "No dudes en hacer cualquier pregunta que tengas sobre Pega Infinity™",
    "app.sampleQuestions": "Explora preguntas de ejemplo",
    "app.ownQuestion": "o introduce tu propia pregunta aquí",
    "app.logoutToolTip": "Cerrar sesión",
    "app.askButtonLabel": "Preguntar",
    "app.initialReply":
      "Gracias por tu pregunta. Necesitaré un breve momento para recopilar la información. Por favor, ten paciencia.",
    "app.newAnswer":
      "Lo siento, estoy ocupado con algunas otras preguntas en este momento. Estaré encantado de ayudarte cuando termine.",
    "app.referencesTitle": "Referencias",
    "app.disclaimerTitle": "Descargo de responsabilidad",
    "app.disclaimerDesktop":
      "Las respuestas generadas por IA pueden ser incorrectas. Es importante no revelar detalles personales y no basar decisiones cruciales en estas respuestas.",
    "app.disclaimerMobile":
      "Las respuestas generadas por IA pueden ser incorrectas.",
    "app.disclaimerModalTitle": "Referencias traducidas automáticamente",
    "app.disclaimerModalText":
      "Está a punto de ser redirigido a una versión traducida automáticamente del sitio de Pega. Tenga en cuenta que estas traducciones no han sido verificadas por precisión o integridad y pueden contener errores, incluido lenguaje inapropiado. Cualquier diferencia o error de la traducción no es legalmente vinculante y no debe utilizarse para cumplimiento o ejecución. En caso de dudas sobre la precisión, consulte la versión oficial en inglés del sitio web.",
    "app.disclaimerModalCheckbox": "No mostrar de nuevo",
    "app.disclaimerModalButton": "Cerrar",
    "app.copyButton": "Copiar",  
    "app.copyText": "¡Copiado al portapapeles!",  
    "app.thumbsUpButton": "Pulgar arriba",  
    "app.thumbsUpText": "¡Gracias por tu opinión!",  
    "app.thumbsDownButton": "Pulgar abajo",  
    "app.thumbsDownText": "¡Gracias por tu opinión!",
    questions: [
      {
        value: "q",
        label:
          "¿Cuáles son los componentes principales de la plataforma Pega y cómo interactúan entre sí?",
      },
      {
        value: "q",
        label:
          "¿De qué manera la funcionalidad de administración de casos de Pega respalda los procesos empresariales complejos y cuáles son las funciones clave que permiten este soporte?",
      },
      {
        value: "q",
        label:
          "¿Puede explicar el papel de la inteligencia artificial en las capacidades de toma de decisiones de Pega y cómo ayuda a personalizar las interacciones con los clientes?",
      },
      {
        value: "q",
        label:
          "¿Qué herramientas de informes y análisis están disponibles en la plataforma de Pega y cómo se pueden utilizar para obtener información sobre el rendimiento empresarial?",
      },
      {
        value: "q",
        label:
          "¿De qué maneras puedo personalizar la interfaz de usuario de una aplicación de Pega para que coincida con los estándares de uso y de marca de mi empresa?",
      },
      {
        value: "q",
        label:
          "¿Qué funciones ofrece Pega Customer Service para permitir interacciones personalizadas con los clientes en función de su historial y preferencias?",
      },
      {
        value: "q",
        label:
          "¿Puede describir las capacidades de administración de casos de Pega Customer Service y cómo ayudan a resolver las consultas de los clientes de manera más eficiente?",
      },
      {
        value: "q",
        label:
          "¿Cómo configuro Pega Customer Service para dirigir automáticamente los casos de los clientes al agente de servicio más adecuado en función de las habilidades y la disponibilidad?",
      },
      {
        value: "q",
        label:
          "¿Cómo implemento un chatbot con Pega Customer Service para gestionar las consultas comunes de los clientes y cuándo se debe convertir en un agente activo?",
      },
      {
        value: "q",
        label:
          "¿Cómo facilita Pega Sales Automation la administración de clientes potenciales y qué herramientas proporciona para priorizar y puntuar los clientes potenciales de manera eficaz?",
      },
      {
        value: "q",
        label:
          "¿Cuáles son las características clave de Pega Sales Automation que respaldan el proceso de administración de la cartera de ventas y cómo contribuyen a cerrar negocios más rápido?",
      },
    ],
  },
  nl: {
    "app.language": "Nederlands",
    "app.subTitle": "Stel gerust alle vragen die je hebt over Pega Infinity™",
    "app.sampleQuestions": "Blader door voorbeeldvragen",
    "app.ownQuestion": "of voer hier je eigen vraag in",
    "app.logoutToolTip": "Uitloggen",
    "app.askButtonLabel": "Vragen",
    "app.initialReply":
      "Bedankt voor je vraag. Ik heb even tijd nodig om de informatie te verzamelen. Even geduld alstublieft.",
    "app.newAnswer":
      "Het spijt me, ik ben momenteel bezig met een paar andere vragen. Ik help je graag zodra ik klaar ben.",
    "app.referencesTitle": "Referenties",
    "app.disclaimerTitle": "Vrijwaring",
    "app.disclaimerDesktop":
      "Door AI gegenereerde antwoorden kunnen onjuist zijn. Het is belangrijk om geen persoonlijke gegevens te onthullen en geen cruciale beslissingen te baseren op deze antwoorden.",
    "app.disclaimerMobile":
      "Door AI gegenereerde antwoorden kunnen onjuist zijn.",
    "app.disclaimerModalTitle": "Automatisch vertaalde verwijzingen",
    "app.disclaimerModalText":
      "U staat op het punt om te worden doorgestuurd naar een automatisch vertaalde versie van de Pega-site. Houd er rekening mee dat deze vertalingen niet zijn gecontroleerd op nauwkeurigheid of volledigheid en fouten kunnen bevatten, waaronder ongepaste taal. Eventuele verschillen of fouten in de vertaling zijn niet juridisch bindend en mogen niet worden gebruikt voor naleving of handhaving. Raadpleeg bij twijfel over de nauwkeurigheid de officiële Engelse versie van de website.",
    "app.disclaimerModalCheckbox": "Niet meer tonen",
    "app.disclaimerModalButton": "Sluiten",
    "app.copyButton": "Kopiëren",  
    "app.copyText": "Gekopieerd naar klembord!",  
    "app.thumbsUpButton": "Duim omhoog",  
    "app.thumbsUpText": "Bedankt voor je feedback!",  
    "app.thumbsDownButton": "Duim omlaag",  
    "app.thumbsDownText": "Bedankt voor je feedback!",
    questions: [
      {
        value: "q",
        label:
          "Wat zijn de kerncomponenten van het Pega-platform, en hoe interageren ze met elkaar?",
      },
      {
        value: "q",
        label:
          "Hoe ondersteunt de casemanagementfunctionaliteit van Pega complexe bedrijfsprocessen, en wat zijn de sleutelfuncties die deze ondersteuning mogelijk maken?",
      },
      {
        value: "q",
        label:
          "Kunt u de rol van kunstmatige intelligentie in de besluitvormingsmogelijkheden van Pega uitleggen, en hoe helpt dit bij het personaliseren van klantinteracties?",
      },
      {
        value: "q",
        label:
          "Welke rapportage- en analysetools zijn beschikbaar op het Pega-platform, en hoe kunnen deze worden ingezet om inzicht te krijgen in bedrijfsprestaties?",
      },
      {
        value: "q",
        label:
          "Op welke manieren kan ik de gebruikersinterface van een Pega-applicatie aanpassen om aan te sluiten bij de huisstijl en gebruiksvriendelijkheidsnormen van mijn bedrijf?",
      },
      {
        value: "q",
        label:
          "Welke functies biedt Pega Customer Service om gepersonaliseerde interacties met klanten te faciliteren op basis van hun geschiedenis en voorkeuren?",
      },
      {
        value: "q",
        label:
          "Kunt u de casemanagementmogelijkheden binnen Pega Customer Service beschrijven, en hoe dragen deze bij aan een efficiëntere afhandeling van klantvragen?",
      },
      {
        value: "q",
        label:
          "Hoe configureer ik Pega Customer Service zodat klantcases automatisch worden gerouteerd naar de meest geschikte servicemedewerker op basis van vaardigheden en beschikbaarheid?",
      },
      {
        value: "q",
        label:
          "Hoe implementeer ik een chatbot in Pega Customer Service voor het afhandelen van veelvoorkomende klantvragen, en wanneer moet deze escaleren naar een live medewerker?",
      },
      {
        value: "q",
        label:
          "Hoe faciliteert Pega Sales Automation leadbeheer, en welke tools biedt het om leads effectief te prioriteren en te scoren?",
      },
      {
        value: "q",
        label:
          "Wat zijn de belangrijkste functies van Pega Sales Automation die het beheer van de verkooppijplijn ondersteunen, en hoe helpen deze bij het sneller sluiten van deals?",
      },
    ],
  },
  fr: {
    "app.language": "Français",
    "app.subTitle":
      "N'hésitez pas à poser toutes les questions que vous pourriez avoir sur Pega Infinity™",
    "app.sampleQuestions": "Parcourez des exemples de questions",
    "app.ownQuestion": "ou saisissez votre propre question ici",
    "app.logoutToolTip": "Se déconnecter",
    "app.askButtonLabel": "Demander",
    "app.initialReply":
      "Merci pour votre question. J'aurai besoin d'un court instant pour rassembler les informations. Veuillez patienter.",
    "app.newAnswer":
      "Je suis désolé, je suis actuellement occupé avec d'autres questions. Je serai heureux de vous aider dès que j'aurai terminé.",
    "app.referencesTitle": "Références",
    "app.disclaimerTitle": "Avertissement",
    "app.disclaimerDesktop":
      "Les réponses générées par l'IA peuvent être incorrectes. Il est important de ne pas divulguer de détails personnels et de ne pas fonder de décisions cruciales sur ces réponses.",
    "app.disclaimerMobile":
      "Les réponses générées par l'IA peuvent être incorrectes.",
    "app.disclaimerModalTitle": "Références traduites automatiquement",
    "app.disclaimerModalText":
      "Vous êtes sur le point d'être redirigé vers une version traduite automatiquement du site Pega. Veuillez noter que ces traductions n'ont pas été vérifiées pour leur exactitude ou leur exhaustivité et peuvent contenir des erreurs, y compris un langage inapproprié. Les différences ou erreurs de traduction ne sont pas juridiquement contraignantes et ne doivent pas être utilisées à des fins de conformité ou d'application. En cas de doute sur l'exactitude, veuillez consulter la version officielle en anglais du site Web.",
    "app.disclaimerModalCheckbox": "Ne plus afficher",
    "app.disclaimerModalButton": "Fermer",
    "app.copyButton": "Copier",  
    "app.copyText": "Copié dans le presse-papier !",  
    "app.thumbsUpButton": "Pouce levé",  
    "app.thumbsUpText": "Merci pour votre retour !",  
    "app.thumbsDownButton": "Pouce baissé",  
    "app.thumbsDownText": "Merci pour votre retour !",
    questions: [
      {
        value: "q",
        label:
          "Quels sont les principaux composants de la plateforme Pega et comment interagissent-ils les uns avec les autres ?",
      },
      {
        value: "q",
        label:
          "Comment la fonctionnalité de gestion des dossiers de Pega prend-elle en charge les processus métier complexes, et quelles sont les principales fonctionnalités qui permettent cette prise en charge ?",
      },
      {
        value: "q",
        label:
          "Pouvez-vous expliquer le rôle de l'intelligence artificielle dans les capacités décisionnelles de Pega et comment elle contribue à personnaliser les interactions avec les clients ?",
      },
      {
        value: "q",
        label:
          "Quels sont les outils de reporting et d'analyse disponibles sur la plateforme Pega, et comment peuvent-ils être utilisés pour obtenir des informations sur les performances de l'entreprise ?",
      },
      {
        value: "q",
        label:
          "Comment puis-je personnaliser l'interface utilisateur d'une application Pega pour qu'elle corresponde aux normes de marque et d'utilisabilité de mon entreprise ?",
      },
      {
        value: "q",
        label:
          "Quelles sont les fonctionnalités proposées par Pega Customer Service pour permettre des interactions personnalisées avec les clients en fonction de leur historique et de leurs préférences ?",
      },
      {
        value: "q",
        label:
          "Pouvez-vous décrire les fonctionnalités de gestion des dossiers de Pega Customer Service et la manière dont elles contribuent à résoudre plus efficacement les demandes des clients ?",
      },
      {
        value: "q",
        label:
          "Comment configurer Pega Customer Service pour qu'il achemine automatiquement les dossiers clients vers l'agent de service le plus approprié en fonction des compétences et de la disponibilité ?",
      },
      {
        value: "q",
        label:
          "Comment mettre en œuvre un chatbot avec Pega Customer Service pour traiter les demandes les plus courantes des clients et quand dois-je les transmettre à un agent en direct ?",
      },
      {
        value: "q",
        label:
          "Comment Pega Sales Automation facilite-t-elle la gestion des prospects et quels outils fournit-elle pour hiérarchiser et évaluer efficacement les prospects ?",
      },
      {
        value: "q",
        label:
          "Quelles sont les principales fonctionnalités de Pega Sales Automation qui soutiennent le processus de gestion du pipeline des ventes, et comment contribuent-elles à accélérer la conclusion de transactions ?",
      },
    ],
  },
  de: {
    "app.language": "Deutsch",
    "app.subTitle":
      "Fühlen Sie sich frei, alle Fragen zu stellen, die Sie zu Pega Infinity™ haben könnten",
    "app.sampleQuestions": "Durchsuchen Sie Beispiel-Fragen",
    "app.ownQuestion": "oder geben Sie hier Ihre eigene Frage ein",
    "app.logoutToolTip": "Abmelden",
    "app.askButtonLabel": "Fragen",
    "app.initialReply":
      "Danke für Ihre Frage. Ich benötige einen kurzen Moment, um die Informationen zu sammeln. Bitte haben Sie Geduld.",
    "app.newAnswer":
      "Es tut mir leid, ich bin gerade mit einigen anderen Fragen beschäftigt. Ich helfe Ihnen gerne weiter, sobald ich fertig bin.",
    "app.referencesTitle": "Referenzen",
    "app.disclaimerTitle": "Haftungsausschluss",
    "app.disclaimerDesktop":
      "Von KI generierte Antworten können falsch sein. Es ist wichtig, keine persönlichen Details preiszugeben und keine entscheidenden Entscheidungen auf diese Antworten zu stützen.",
    "app.disclaimerMobile": "Von KI generierte Antworten können falsch sein.",
    "app.disclaimerModalTitle": "Automatisch übersetzte Verweise",
    "app.disclaimerModalText":
      "Sie werden gleich auf eine automatisch übersetzte Version der Pega-Website weitergeleitet. Bitte beachten Sie, dass diese Übersetzungen nicht auf Genauigkeit oder Vollständigkeit überprüft wurden und Fehler, einschließlich unangemessener Sprache, enthalten können. Etwaige Unterschiede oder Fehler in der Übersetzung sind rechtlich nicht bindend und sollten nicht für die Einhaltung oder Durchsetzung verwendet werden. Bei Bedenken hinsichtlich der Genauigkeit konsultieren Sie bitte die offizielle englische Version der Website.",
    "app.disclaimerModalCheckbox": "Nicht mehr anzeigen",
    "app.disclaimerModalButton": "Schließen",
    "app.copyButton": "Kopieren",  
    "app.copyText": "In die Zwischenablage kopiert!",  
    "app.thumbsUpButton": "Daumen hoch",  
    "app.thumbsUpText": "Danke für das Feedback!",  
    "app.thumbsDownButton": "Daumen runter",  
    "app.thumbsDownText": "Danke für das Feedback!",
    questions: [
      {
        value: "q",
        label:
          "Was sind die Kernkomponenten der Pega-Plattform und wie interagieren sie miteinander?",
      },
      {
        value: "q",
        label:
          "Wie unterstützt die Case-Management-Funktionalität von Pega komplexe Geschäftsprozesse, und was sind die wichtigsten Funktionen, die diese Unterstützung ermöglichen?",
      },
      {
        value: "q",
        label:
          "Können Sie erklären, welche Rolle künstliche Intelligenz in den Entscheidungsfindungsfunktionen von Pega spielt und wie sie bei der Personalisierung von Kundeninteraktionen hilft?",
      },
      {
        value: "q",
        label:
          "Welche Berichts- und Analysetools sind auf der Pega-Plattform verfügbar und wie können sie genutzt werden, um Einblicke in die Geschäftsleistung zu gewinnen?",
      },
      {
        value: "q",
        label:
          "Wie kann ich die Benutzeroberfläche einer Pega-Anwendung an die Branding- und Usability-Standards meines Unternehmens anpassen?",
      },
      {
        value: "q",
        label:
          "Welche Funktionen bietet der Pega-Kundenservice, um personalisierte Interaktionen mit Kunden auf der Grundlage ihrer Historie und Präferenzen zu ermöglichen?",
      },
      {
        value: "q",
        label:
          "Können Sie die Fallmanagement-Funktionen des Pega-Kundendienstes beschreiben und wie sie dazu beitragen, Kundenanfragen effizienter zu lösen?",
      },
      {
        value: "q",
        label:
          "Wie konfiguriere ich den Pega-Kundenservice so, dass Kundenfälle je nach Fähigkeiten und Verfügbarkeit automatisch an den am besten geeigneten Servicemitarbeiter weitergeleitet werden?",
      },
      {
        value: "q",
        label:
          "Wie implementiere ich einen Chatbot mit dem Pega-Kundenservice, um häufig gestellte Kundenanfragen zu bearbeiten, und wann sollte er an einen Live-Agenten weitergeleitet werden?",
      },
      {
        value: "q",
        label:
          "Wie erleichtert Pega Sales Automation das Leadmanagement und welche Tools bietet es, um Leads effektiv zu priorisieren und zu bewerten?",
      },
      {
        value: "q",
        label:
          "Was sind die wichtigsten Funktionen von Pega Sales Automation, die den Prozess des Vertriebspipeline-Managements unterstützen, und wie tragen sie dazu bei, Geschäfte schneller abzuschließen?",
      },
    ],
  },
  pt: {
    "app.language": "Português",
    "app.subTitle":
      "Sinta-se à vontade para fazer qualquer pergunta que possa ter sobre o Pega Infinity™",
    "app.sampleQuestions": "Navegue pelas perguntas de exemplo",
    "app.ownQuestion": "ou insira sua própria pergunta aqui",
    "app.logoutToolTip": "Sair",
    "app.askButtonLabel": "Perguntar",
    "app.initialReply":
      "Obrigado pela sua pergunta. Vou precisar de um momento para reunir as informações. Por favor, aguarde.",
    "app.newAnswer":
      "Desculpe, estou ocupado com outras perguntas no momento. Ficarei feliz em ajudar assim que terminar.",
    "app.referencesTitle": "Referências",
    "app.disclaimerTitle": "Aviso Legal",
    "app.disclaimerDesktop":
      "As respostas geradas por IA podem estar incorretas. É importante não divulgar detalhes pessoais e não basear decisões críticas nessas respostas.",
    "app.disclaimerMobile":
      "As respostas geradas por IA podem estar incorretas.",
    "app.disclaimerModalTitle": "Referências traduzidas automaticamente",
    "app.disclaimerModalText":
      "Você está prestes a ser redirecionado para uma versão traduzida automaticamente do site da Pega. Observe que essas traduções não foram verificadas quanto à precisão ou integridade e podem conter erros, incluindo linguagem inadequada. Quaisquer diferenças ou erros na tradução não são legalmente vinculativos e não devem ser usados para conformidade ou execução. Em caso de dúvidas sobre a precisão, consulte a versão oficial em inglês do site.",
    "app.disclaimerModalCheckbox": "Não mostrar novamente",
    "app.disclaimerModalButton": "Fechar",
    "app.copyButton": "Copiar",  
    "app.copyText": "Copiado para a área de transferência!",  
    "app.thumbsUpButton": "Polegar para cima",  
    "app.thumbsUpText": "Obrigado pelo feedback!",  
    "app.thumbsDownButton": "Polegar para baixo",  
    "app.thumbsDownText": "Obrigado pelo feedback!",
    questions: [
      {
        value: "q",
        label:
          "Quais são os principais componentes da plataforma Pega e como eles interagem uns com os outros?",
      },
      {
        value: "q",
        label:
          "Como a funcionalidade de gerenciamento de casos da Pega oferece suporte a processos comerciais complexos e quais são os principais recursos que permitem esse suporte?",
      },
      {
        value: "q",
        label:
          "Você pode explicar o papel da inteligência artificial nas capacidades de tomada de decisão da Pega e como ela ajuda na personalização das interações com os clientes?",
      },
      {
        value: "q",
        label:
          "Quais ferramentas de relatórios e análises estão disponíveis na Plataforma Pega e como elas podem ser utilizadas para obter insights sobre o desempenho dos negócios?",
      },
      {
        value: "q",
        label:
          "De que forma posso personalizar a interface de usuário de um aplicativo da Pega para atender aos padrões de marca e usabilidade da minha empresa?",
      },
      {
        value: "q",
        label:
          "Quais recursos o Pega Customer Service oferece para permitir interações personalizadas com os clientes com base em seu histórico e preferências?",
      },
      {
        value: "q",
        label:
          "Você pode descrever os recursos de gerenciamento de casos do Pega Customer Service e como eles ajudam a resolver as dúvidas dos clientes com mais eficiência?",
      },
      {
        value: "q",
        label:
          "Como configuro o Pega Customer Service para encaminhar automaticamente os casos de clientes para o agente de atendimento mais adequado com base nas habilidades e na disponibilidade?",
      },
      {
        value: "q",
        label:
          "Como implemento um chatbot com o Pega Customer Service para lidar com as dúvidas comuns dos clientes e quando ele deve ser encaminhado para um agente ao vivo?",
      },
      {
        value: "q",
        label:
          "Como o Pega Sales Automation facilita o gerenciamento de leads e quais ferramentas ele fornece para priorizar e pontuar leads de forma eficaz?",
      },
      {
        value: "q",
        label:
          "Quais são os principais recursos do Pega Sales Automation que apoiam o processo de gerenciamento do funil de vendas e como eles contribuem para fechar negócios com mais rapidez?",
      },
    ],
  },
  pl: {
    "app.language": "Polski",
    "app.subTitle":
      "Zachęcamy do zadawania wszelkich pytań dotyczących Pega Infinity™",
    "app.sampleQuestions": "Przeglądaj przykładowe pytania",
    "app.ownQuestion": "lub wpisz tutaj swoje własne pytanie",
    "app.logoutToolTip": "Wyloguj się",
    "app.askButtonLabel": "Zapytaj",
    "app.initialReply":
      "Dziękuję za Twoje pytanie. Potrzebuję chwili, aby zebrać informacje. Proszę czekać.",
    "app.newAnswer":
      "Przepraszam, obecnie zajmuję się innymi pytaniami. Chętnie pomogę, jak tylko skończę.",
    "app.referencesTitle": "Odniesienia",
    "app.disclaimerTitle": "Zastrzeżenie",
    "app.disclaimerDesktop":
      "Odpowiedzi wygenerowane przez AI mogą być niepoprawne. Ważne jest, aby nie ujawniać danych osobowych i nie podejmować kluczowych decyzji na podstawie tych odpowiedzi.",
    "app.disclaimerMobile":
      "Odpowiedzi wygenerowane przez AI mogą być niepoprawne.",
    "app.disclaimerModalTitle": "Automatycznie tłumaczone odniesienia",
    "app.disclaimerModalText":
      "Zaraz zostaniesz przekierowany na automatycznie przetłumaczoną wersję strony Pega. Należy pamiętać, że te tłumaczenia nie zostały sprawdzone pod kątem dokładności ani kompletności i mogą zawierać błędy, w tym nieodpowiedni język. Wszelkie różnice lub błędy w tłumaczeniu nie są prawnie wiążące i nie powinny być wykorzystywane do celów zgodności lub egzekwowania. W przypadku wątpliwości dotyczących dokładności zapoznaj się z oficjalną angielską wersją strony internetowej.",
    "app.disclaimerModalCheckbox": "Nie pokazuj ponownie",
    "app.disclaimerModalButton": "Zamknij",
    "app.copyButton": "Kopiuj",  
    "app.copyText": "Skopiowano do schowka!",  
    "app.thumbsUpButton": "Kciuk w górę",  
    "app.thumbsUpText": "Dziękujemy za opinię!",  
    "app.thumbsDownButton": "Kciuk w dół",  
    "app.thumbsDownText": "Dziękujemy za opinię!",
    questions: [
      {
        value: "q",
        label:
          "Jakie są podstawowe elementy platformy Pega i jak wchodzą ze sobą w interakcje?",
      },
      {
        value: "q",
        label:
          "W jaki sposób funkcjonalność zarządzania sprawami firmy Pega wspiera złożone procesy biznesowe i jakie są kluczowe funkcje, które umożliwiają to wsparcie?",
      },
      {
        value: "q",
        label:
          "Czy potrafisz wyjaśnić rolę sztucznej inteligencji w możliwościach decyzyjnych firmy Pega i jak pomaga ona w personalizacji interakcji z klientami?",
      },
      {
        value: "q",
        label:
          "Jakie narzędzia do raportowania i analizy są dostępne w Platformie Pega i w jaki sposób można je wykorzystać do uzyskania wglądu w wyniki biznesowe?",
      },
      {
        value: "q",
        label:
          "W jaki sposób mogę dostosować interfejs użytkownika aplikacji Pega do standardów marki i użyteczności mojej firmy?",
      },
      {
        value: "q",
        label:
          "Jakie funkcje oferuje obsługa Customer Pega, aby umożliwić spersonalizowane interakcje z klientami w oparciu o ich historię i preferencje?",
      },
      {
        value: "q",
        label:
          "Czy możesz opisać możliwości zarządzania sprawami w ramach działu obsługi Customer Pega i jak pomagają one w skuteczniejszym rozwiązywaniu zapytań klientów?",
      },
      {
        value: "q",
        label:
          "Jak skonfigurować obsługę Customer Pega, aby automatycznie kierował sprawy klientów do najbardziej odpowiedniego agenta serwisowego w oparciu o umiejętności i dostępność?",
      },
      {
        value: "q",
        label:
          "Jak wdrożyć chatbota z obsługą Customer Pega, aby obsługiwać typowe zapytania klientów i kiedy powinien on przekształcić się w agenta na żywo?",
      },
      {
        value: "q",
        label:
          "W jaki sposób Pega Sales Automation ułatwia zarządzanie potencjalnymi klientami i jakie narzędzia zapewnia do skutecznego ustalania priorytetów i pozyskiwania potencjalnych klientów?",
      },
      {
        value: "q",
        label:
          "Jakie są kluczowe cechy Pega Sales Automation, które wspierają proces zarządzania rurociągiem sprzedaży i w jaki sposób przyczyniają się one do szybszego zamykania transakcji?",
      },
    ],
  },
  it: {
    "app.language": "Italiano",
    "app.subTitle":
      "Sentiti libero di fare qualsiasi domanda tu abbia su Pega Infinity™",
    "app.sampleQuestions": "Esplora le domande di esempio",
    "app.ownQuestion": "oppure inserisci la tua domanda qui",
    "app.logoutToolTip": "Esci",
    "app.askButtonLabel": "Chiedi",
    "app.initialReply":
      "Grazie per la tua domanda. Avrò bisogno di un momento per raccogliere le informazioni. Per favore, attendi.",
    "app.newAnswer":
      "Mi dispiace, sono impegnato con altre domande al momento. Sarò felice di aiutarti non appena avrò finito.",
    "app.referencesTitle": "Riferimenti",
    "app.disclaimerTitle": "Avviso Legale",
    "app.disclaimerDesktop":
      "Le risposte generate dall'IA possono essere errate. È importante non divulgare dettagli personali e non basare decisioni critiche su queste risposte.",
    "app.disclaimerMobile":
      "Le risposte generate dall'IA possono essere errate.",
    "app.disclaimerModalTitle": "Riferimenti tradotti automaticamente",
    "app.disclaimerModalText":
      "Stai per essere reindirizzato a una versione tradotta automaticamente del sito Pega. Si prega di notare che queste traduzioni non sono state verificate per accuratezza o completezza e potrebbero contenere errori, inclusi linguaggio inappropriato. Eventuali differenze o errori nella traduzione non sono legalmente vincolanti e non devono essere utilizzati per la conformità o l'applicazione. In caso di dubbi sull'accuratezza, consultare la versione ufficiale in inglese del sito web.",
    "app.disclaimerModalCheckbox": "Non mostrare più",
    "app.disclaimerModalButton": "Chiudi",
    "app.copyButton": "Copia",  
    "app.copyText": "Copiato negli appunti!",  
    "app.thumbsUpButton": "Pollice in su",  
    "app.thumbsUpText": "Grazie per il feedback!",  
    "app.thumbsDownButton": "Pollice in giù",  
    "app.thumbsDownText": "Grazie per il feedback!",
    questions: [
      {
        value: "q",
        label:
          "Quali sono i componenti principali della piattaforma Pega e come interagiscono tra loro?",
      },
      {
        value: "q",
        label:
          "In che modo la funzionalità di gestione dei casi di Pega supporta processi aziendali complessi e quali sono le caratteristiche chiave che consentono questo supporto?",
      },
      {
        value: "q",
        label:
          "Puoi spiegare il ruolo dell'intelligenza artificiale nelle capacità decisionali di Pega e in che modo aiuta a personalizzare le interazioni con i clienti?",
      },
      {
        value: "q",
        label:
          "Quali strumenti di reporting e analisi sono disponibili nella piattaforma Pega e come possono essere utilizzati per ottenere informazioni dettagliate sulle prestazioni aziendali?",
      },
      {
        value: "q",
        label:
          "In che modo posso personalizzare l'interfaccia utente di un'applicazione Pega per adattarla agli standard di branding e usabilità della mia azienda?",
      },
      {
        value: "q",
        label:
          "Quali funzionalità offre Pega Customer Service per consentire interazioni personalizzate con i clienti in base alla loro cronologia e alle loro preferenze?",
      },
      {
        value: "q",
        label:
          "Puoi descrivere le funzionalità di gestione dei casi all'interno di Pega Customer Service e come aiutano a risolvere le richieste dei clienti in modo più efficiente?",
      },
      {
        value: "q",
        label:
          "Come posso configurare Pega Customer Service per indirizzare automaticamente i casi dei clienti all'agente di assistenza più appropriato in base alle competenze e alla disponibilità?",
      },
      {
        value: "q",
        label:
          "Come posso implementare un chatbot con Pega Customer Service per gestire le richieste più comuni dei clienti e quando devo inoltrarle a un agente in tempo reale?",
      },
      {
        value: "q",
        label:
          "In che modo Pega Sales Automation facilita la gestione dei lead e quali strumenti fornisce per assegnare priorità e assegnare un punteggio ai lead in modo efficace?",
      },
      {
        value: "q",
        label:
          "Quali sono le caratteristiche principali di Pega Sales Automation che supportano il processo di gestione della pipeline di vendita e in che modo contribuiscono a concludere le trattative più rapidamente?",
      },
    ],
  },
  ja: {
    "app.language": "日本語",
    "app.subTitle": "Pega Infinity™に関する質問は何でもお気軽にどうぞ",
    "app.sampleQuestions": "サンプルの質問を見る",
    "app.ownQuestion": "またはこちらにご自身の質問を入力してください",
    "app.logoutToolTip": "ログアウト",
    "app.askButtonLabel": "質問する",
    "app.initialReply":
      "ご質問ありがとうございます。情報を集めるために少々お時間をいただきます。お待ちください。",
    "app.newAnswer":
      "申し訳ありませんが,現在他の質問に取り組んでいます。終わり次第,喜んでお手伝いします。",
    "app.referencesTitle": "参照",
    "app.disclaimerTitle": "免責事項",
    "app.disclaimerDesktop":
      "AIによって生成された回答は正しくない可能性があります。個人情報を開示しないでくださいし,これらの回答に基づいて重要な決定をしないでください。",
    "app.disclaimerMobile":
      "AIによって生成された回答は正しくない可能性があります。",
    "app.disclaimerModalTitle": "自動翻訳された参照",
    "app.disclaimerModalText":
      "Pegaサイトの自動翻訳版にリダイレクトされようとしています。これらの翻訳は正確性や完全性について確認されておらず、不適切な言葉を含むエラーが含まれている可能性があります。翻訳の違いや間違いは法的拘束力がなく、コンプライアンスや執行のために使用しないでください。正確性に関する懸念がある場合は、公式の英語版のウェブサイトを参照してください。",
    "app.disclaimerModalCheckbox": "再表示しない",
    "app.disclaimerModalButton": "閉じる",
    "app.copyButton": "コピー",  
    "app.copyText": "クリップボードにコピーされました！",  
    "app.thumbsUpButton": "いいね",  
    "app.thumbsUpText": "フィードバックをありがとう！",  
    "app.thumbsDownButton": "よくないね",  
    "app.thumbsDownText": "フィードバックをありがとう！",
    questions: [
      {
        value: "q",
        label:
          "Pega Platformのコアコンポーネントにはどのようなものがあり、それらはどのように相互に作用しますか？",
      },
      {
        value: "q",
        label:
          "ペガのケース管理機能は複雑なビジネスプロセスをどのようにサポートしていますか？また、このサポートを可能にする主な機能は何ですか？",
      },
      {
        value: "q",
        label:
          "ペガの意思決定機能における人工知能の役割と、それが顧客とのやりとりのパーソナライズにどのように役立つかを説明していただけますか？",
      },
      {
        value: "q",
        label:
          "Pega Platformにはどのようなレポートツールや分析ツールがありますか？また、それらをどのように活用して業績に関する洞察を得ることができますか？",
      },
      {
        value: "q",
        label:
          "ペガアプリケーションのユーザーインターフェースを会社のブランディングやユーザビリティの基準に合わせてカスタマイズするにはどうすればいいですか？",
      },
      {
        value: "q",
        label:
          "Pega Customer Service eでは、顧客の履歴や好みに基づいて顧客との個別対応を可能にするためにどのような機能を提供していますか？",
      },
      {
        value: "q",
        label:
          "Pega Customer Service eのケース管理機能と、それが顧客からの問い合わせをより効率的に解決するのにどのように役立つかについて説明してください。",
      },
      {
        value: "q",
        label:
          "スキルと空き状況に基づいてCustomer ケースを最も適切なService エージェントに自動的に転送するようにペガカスタマーサービスを設定する方法を教えてください。",
      },
      {
        value: "q",
        label:
          "Pega Customer Service でチャットボットを実装して一般的な顧客からの問い合わせを処理する方法と、それをライブエージェントにエスカレーションすべきタイミングを教えてください。",
      },
      {
        value: "q",
        label:
          "Pega Sales Automation はリード管理をどのように促進しますか? また、リードの優先順位付けとスコアリングを効果的に行うためにどのようなツールが提供されていますか?",
      },
      {
        value: "q",
        label:
          "Pega Sales Automationのセールスパイプライン管理プロセスをサポートする主な機能は何か？また、それらはどのようにディールをより早く成立させるのに役立つのか？",
      },
    ],
  },
  ar: {
    "app.language": "العربية",
    "app.subTitle": "لا تتردد في طرح أي أسئلة قد تكون لديك حول Pega Infinity™",
    "app.sampleQuestions": "تصفح أسئلة العينة",
    "app.ownQuestion": "أو أدخل سؤالك الخاص هنا",
    "app.logoutToolTip": "تسجيل الخروج",
    "app.askButtonLabel": "اسأل",
    "app.initialReply":
      "شكرًا لسؤالك. أحتاج لحظة لجمع المعلومات. الرجاء الانتظار.",
    "app.newAnswer":
      "أنا آسف، أنا مشغول ببعض الأسئلة الأخرى في الوقت الحالي. سأكون سعيدًا بالمساعدة عندما أنتهي.",
    "app.referencesTitle": "المراجع",
    "app.disclaimerTitle": "إخلاء المسؤولية",
    "app.disclaimerDesktop":
      "قد تكون الإجابات التي تولدها الذكاء الاصطناعي غير صحيحة. من المهم عدم الكشف عن التفاصيل الشخصية وعدم اتخاذ قرارات حاسمة استنادًا إلى هذه الإجابات.",
    "app.disclaimerMobile":
      "قد تكون الإجابات التي تولدها الذكاء الاصطناعي غير صحيحة.",
    "app.disclaimerModalTitle": "مراجع مترجمة تلقائيًا",
    "app.disclaimerModalText":
      "أنت على وشك إعادة التوجيه إلى نسخة مترجمة تلقائيًا من موقع Pega. يرجى ملاحظة أن هذه الترجمات لم يتم التحقق من دقتها أو اكتمالها وقد تحتوي على أخطاء بما في ذلك لغة غير لائقة. أي اختلافات أو أخطاء في الترجمة ليست ملزمة قانونًا ولا ينبغي استخدامها للامتثال أو التنفيذ. في حالة وجود مخاوف بشأن الدقة، يرجى الرجوع إلى النسخة الإنجليزية الرسمية من الموقع.",
    "app.disclaimerModalCheckbox": "لا تظهر مرة أخرى",
    "app.disclaimerModalButton": "إغلاق",
    "app.copyButton": "نسخ",  
    "app.copyText": "تم النسخ إلى الحافظة!",  
    "app.thumbsUpButton": "أعجبني",  
    "app.thumbsUpText": "شكرًا على ملاحظاتك!",  
    "app.thumbsDownButton": "لم يعجبني",  
    "app.thumbsDownText": "شكرًا على ملاحظاتك!",
    questions: [
      {
        value: "q",
        label:
          "ما هي المكونات الأساسية لمنصة Pega، وكيف تتفاعل مع بعضها البعض؟",
      },
      {
        value: "q",
        label:
          "كيف تدعم وظيفة إدارة الحالات في Pega العمليات التجارية المعقدة، وما هي الميزات الرئيسية التي تتيح هذا الدعم؟",
      },
      {
        value: "q",
        label:
          "هل يمكنك شرح دور الذكاء الاصطناعي في قدرات Pega على اتخاذ القرار، وكيف يساعد في تخصيص تفاعلات العملاء؟",
      },
      {
        value: "q",
        label:
          "ما هي أدوات إعداد التقارير والتحليلات المتوفرة في منصة Pega، وكيف يمكن استخدامها للحصول على رؤى حول أداء الأعمال؟",
      },
      {
        value: "q",
        label:
          "ما هي الطرق التي يمكنني بها تخصيص واجهة المستخدم لتطبيق Pega لتتناسب مع العلامة التجارية لشركتي ومعايير قابلية الاستخدام؟",
      },
      {
        value: "q",
        label:
          "ما الميزات التي تقدمها Pega Customer Service لتمكين التفاعلات الشخصية مع العملاء بناءً على تاريخهم وتفضيلاتهم؟",
      },
      {
        value: "q",
        label:
          "هل يمكنك وصف إمكانيات إدارة الحالات داخل Pega Customer Service وكيف تساعد في حل استفسارات العملاء بشكل أكثر كفاءة؟",
      },
      {
        value: "q",
        label:
          "كيف أقوم بتكوين Pega Customer Service لتوجيه حالات العملاء تلقائيًا إلى وكيل الخدمة الأنسب بناءً على المهارات والتوافر؟",
      },
      {
        value: "q",
        label:
          "كيف يمكنني تنفيذ روبوت محادثة مع Pega Customer Service للتعامل مع استفسارات العملاء الشائعة ومتى يجب أن يتصاعد إلى وكيل مباشر؟",
      },
      {
        value: "q",
        label:
          "كيف تسهل Pega Sales Automation إدارة العملاء المحتملين، وما الأدوات التي توفرها لتحديد الأولويات وتسجيل العملاء المحتملين بشكل فعال؟",
      },
      {
        value: "q",
        label:
          "ما هي الميزات الرئيسية لـ Pega Sales Automation التي تدعم عملية إدارة خطوط أنابيب المبيعات، وكيف تساهم في إغلاق الصفقات بشكل أسرع؟",
      },
    ],
  },
  // ... other languages
};

export default messages;
